<template>
  <v-container v-if="lesson">
    <v-form ref="form" v-model="valid" lazy-validation @submit="saveLesson">
      <div class="pt-3 pb-5 pl-3 d-flex justify-space-between">
        <NavTrail :links="[{ text: 'Video Series', href: '/course-list-type' }, { text: lesson.name }]"></NavTrail>
        <v-switch v-if="auth.editMode" v-model="lesson.status" label="Active" false-value="in-active"
          true-value="active" no-details class="ml-5 mt-0 pt-0">
        </v-switch>
      </div>

      <div class="text-center" v-if="auth.editMode">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field :rules="[(v) => !!v || 'Title is required']" required label="Title"
              v-model="lesson.name"></v-text-field>
          </v-col>
          <v-col cols="12" md="4"> </v-col>
        </v-row>
      </div>
      <div class="text-center" v-if="auth.editMode">
        <v-row>
          <v-col cols="4">
            <v-select required :rules="[(v) => !!v || 'Section is required']" v-model="lesson.sectionId"
              item-text="name" item-value="_id" :items="seriesSections" label="Section" outlined hide-details dense>
            </v-select>
            <v-btn x-small text color="primary" @click="addSectionDialog = true">
              add section</v-btn>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Estimated Time" placeholder="5:00" v-model="lesson.videoLength"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field label="Unlock Code" v-model="lesson.unloackCode"></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="lesson pb-12" v-if="this.lesson._id && this.lesson._id !== 'new'">
        <!-- <div>
        <v-btn href="/course-list-type" color="primary"> Back To List </v-btn>
      </div> -->

        <!-- <h2 class="text-center mb-5">Video Series - 5 Unspoken Reasons 1</h2> -->
        <div class="text-center" v-if="auth.editMode">
          <div class="text-center" v-if="this.lesson._id && this.lesson._id != 'new'">
            <v-progress-circular v-if="loadingVideo" indeterminate color="primary"></v-progress-circular>
            <div class="text-center" v-if="loadingVideo">Uploading Video......</div>
            <v-row>
              <v-col cols="6">
                <v-file-input ref="thumbfileupload" :placeholder="lesson.previewURL" accept="image/*" show-size
                  prepend-icon="mdi-file" label="Upload Thumbnail Image" @change="uploadThumbnail"></v-file-input>
              </v-col>
              <v-col cols="6">
                <v-img class="lesson-image mx-auto mb-3"
                  :src="lesson.previewURL ? lesson.previewURL : '/img/placeholder-image.png'" height="121px"
                  width="220px" fill></v-img>
              </v-col>
            </v-row>
            <v-file-input ref="fileupload" :placeholder="lesson.videoURL" accept="image/mp4" show-size
              prepend-icon="mdi-video" label="Upload Video" @change="uploadVideo"></v-file-input>
          </div>
          <div class="text-center mb-5" v-if="!this.lesson._id || this.lesson._id === 'new'">
            <small>Please save the lesson to upload a video. </small>
          </div>
        </div>
        <div id="video-wrapper">
          <video ref="mainVideo" controls controlslist="nodownload">
            <source type="video/mp4" />
          </video>
        </div>
        <v-row>
          <v-col cols="4">
            <span v-if="!auth.editMode"> Host:</span>
            <span class="text-h6" v-if="!auth.editMode">
              {{ lesson.hostName }}
            </span>
            <v-text-field v-if="auth.editMode" label="Host" v-model="lesson.hostName"></v-text-field>
          </v-col>
          <v-col cols="8"> </v-col>
          <v-col cols="12">
            <div v-if="auth.editMode">
              <VueEditor v-model="lesson.description"></VueEditor>
            </div>
            <div v-html="lesson.description" v-if="!auth.editMode"></div>
          </v-col>
        </v-row>
      </div>
      <div class="text-center save-btn-wrapper" v-if="auth.editMode">
        <v-btn type="submit" class="ma-5 pa-5" color="primary"> Save </v-btn>
      </div>
    </v-form>
    <v-dialog v-model="addSectionDialog" min-width="200" max-width="590">
      <v-card>
        <div class="text-right">
          <v-spacer></v-spacer>
          <v-btn class="mr-2" icon @click="addSectionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <h2 class="text-center mb-2">Add Sections</h2>
        <v-card-text>
          <div class="mt-1">
            <v-text-field class="promo-code-input" :rules="newSection ? [(v) => !!v || 'Section is required'] : []"
              v-model="newSection" label="Section Name"></v-text-field>
            <div class="text-center">
              <v-btn small @click="addSection()" color="primary">Add Section</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarSaved" top>
      Saved!
      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbarSaved = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style lang="scss" scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
// @import "~quill/dist/quill.snow.css";
.save-btn-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  z-index: 99;
}

.lesson {
  position: relative;
  border: red 1px solid;
  padding: 50px;
}

#video-wrapper {
  margin: auto;
  width: 100%;
}

video {
  width: 100% !important;
  height: auto !important;
}

.editable {
  border: 1px solid blue;
}

.edit-section-header {}
</style>
<script>
import { mapState } from "vuex";
import NavTrail from "@/components/NavTrail";
import GET_LESSON from "../graphql/GetLesson.gql";
import SAVE_LESSON from "../graphql/SaveLesson.gql";
import { VueEditor } from "vue2-editor";
import GET_SECTION from "../graphql/GetSection.gql";
import ADD_SECTION from "../graphql/AddSection.gql";

import { getDefaultOptions } from "../vue-apollo";

export default {
  components: { NavTrail, VueEditor },
  data() {
    return {
      lesson: null,
      getLessonError: null,
      loadingVideo: false,
      file: null,
      seriesSections: null,
      valid: null,
      newSection: null,
      addSectionDialog: false,
      snackbarSaved: false,
    };
  },
  mounted() {
    if (this.$route.params.lessonId && this.$route.params.lessonId != "new") {
      this.getLesson();
    } else {
      this.lesson = {};
    }
    this.getSeriesSectionList();
  },
  computed: mapState(["auth"]),
  methods: {
    setVideo() {
      const mainVideo = this.$refs.mainVideo;
      mainVideo.src = this.lesson.videoURL;
      try {
        if (
          this.$route.params.lessonId &&
          this.$route.params.lessonId != "new" &&
          !this.auth.editMode
        ) {
          mainVideo.play();
        }
      } catch (error) { }
      this.$forceUpdate();
    },
    play() {
      const mainVideo = this.$refs.mainVideo;
      mainVideo.play();
    },
    pause() {
      const mainVideo = this.$refs.mainVideo;
      mainVideo.pause();
    },
    async getLesson() {
      const lessonId = this.$route.params.lessonId;
      if (!lessonId) {
        alert("Missing lesson id!");
        return;
      }
      const getLessonResult = await this.$apollo
        .query({
          query: GET_LESSON,
          fetchPolicy: "no-cache",
          variables: {
            _id: lessonId,
          },
        })
        .catch((error) => {
          // check if demo
          console.log("error", error);
          if (error.message.indexOf("demo-user-error") > -1) {
            // not authed to view  this lesson with a demo account
            this.dialogDemoError = true;
            return;
          }
          if (error.message.indexOf("Not Active Account") > -1) {
            // not authed to view  this lesson with a demo account
            alert("This account is not active.");
            this.$router.push("/sign-in");
            return;
          }
          // check if demo

          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      if (!getLessonResult) {
        return;
      }
      this.lesson = getLessonResult.data.lesson[0];

      setTimeout(() => {
        this.setVideo();
      }, 1000);
    },
    async uploadThumbnail(file) {
      const self = this;
      if (file && file.name) {
        console.log("file browser", file);
        this.file = file;
        var data = new FormData();
        data.append("file", file);
        data.append("lessonId", this.lesson._id);
        data.append("namepostfix", "_thumb");

        const apiOptions = getDefaultOptions();

        const uploadFileResponse = await fetch(`${apiOptions.rootHttpEndpoint}/upload-file`, {
          // const uploadJobFileResponse = await fetch(
          //   "http://test-api.gapsellingonlinetraining.com/upload-file",
          //   {
          method: "POST",
          body: data,
        });
        const uploadFile = await uploadFileResponse.json();
        setTimeout(() => {
          self.lesson.previewURL = uploadFile.url;
        }, 2000);
        this.$forceUpdate();
        this.saveLesson();
      }
    },
    async uploadVideo(file) {
      if (file && file.name) {
        console.log("file browser", file);
        this.file = file;
        this.previewVideo();
        this.loadingVideo = true;
        var data = new FormData();
        data.append("file", file);
        data.append("lessonId", this.lesson._id);

        const apiOptions = getDefaultOptions();

        const uploadFileResponse = await fetch(`${apiOptions.rootHttpEndpoint}/upload-file`, {
          method: "POST",
          body: data,
        });
        const uploadFile = await uploadFileResponse.json();
        this.lesson.videoURL = uploadFile.url;
        this.saveLesson();
        this.loadingVideo = false;
        // this.$refs.fileupload.value = null;
      }
    },
    previewVideo() {
      const mainVideo = this.$refs.mainVideo;
      let reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.addEventListener("load", function () {
        mainVideo.src = reader.result;
      });
    },
    async saveLesson(evt) {
      let isValid;
      if (evt) {
        evt.preventDefault();
        isValid = this.$refs.form.validate();
        if (!isValid) {
          return;
        }
        if (!this.lesson?.sectionId) {
          return alert("Please select a Section");
        }
        if (!this.lesson?.name) {
          return alert("Please enter a Title");
        }
      }

      const updatedLesson = Object.assign({}, this.lesson);
      delete updatedLesson.parentLesson;
      delete updatedLesson.__typename;
      delete updatedLesson.questions;
      delete updatedLesson.nextLesson;
      delete updatedLesson.section;
      delete updatedLesson.userNote;

      updatedLesson.type = "series";
      const saveLessonResult = await this.$apollo
        .mutate({
          mutation: SAVE_LESSON,
          variables: { input: updatedLesson },
        })
        .catch((error) => {
          this.saveAnswerError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "Save note error.";
        });
      console.log("saved", saveLessonResult.data.saveLesson);
      // alert("Saved!");
      this.snackbarSaved = true;
      if (saveLessonResult.data.saveLesson.id != this.$route.params.lessonId) {
        this.$router.push(`/lesson/${saveLessonResult.data.saveLesson.id}`);
        window.location.reload();
      }
    },
    async getSeriesSectionList() {
      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            groupId: "default",
            type: "series",
          },
        })
        .catch((error) => {
          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      this.seriesSections = getSectionResult.data.section;
    },
    async addSection() {
      if (!this.newSection) {
        alert("Missing Section Name");
        return;
      }
      const addedSectionResult = await this.$apollo.mutate({
        mutation: ADD_SECTION,
        variables: {
          sectionName: this.newSection,
        },
      });

      this.getSeriesSectionList();
      this.newSection = null;
      this.addSectionDialog = false;
    },
  },
};
</script>
